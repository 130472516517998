@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap");

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Nunito";
	/* color: #3a3a3a; */
}

html {
	/* overflow-x: hidden; */
	background-color: #ffffff;
}

body {
	background-color: #ffffff;
}

nav {
	width: 100%;
}

form {
	width: 100%;
}
/* START default <a></a> styling */
a {
	color: #333;
	text-decoration: none;
}

a:visited {
	color: #333;
}
a:hover {
	color: #333;
}
a:focus {
	color: #333;
}
a:hover,
a:active {
	color: #333;
}
/* END default <a></a> styling */

/* START SCROLLBAR CSS */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar {
	width: 8px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	display: none;
}
/* END SCROLLBAR CSS */
